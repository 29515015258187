import { useCallback } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

export const useEditorOnboardingEvents = () => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireGetEditorOnboardingStateFailed = useCallback(
		(source: any) => {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'failed',
					actionSubject: 'getOnboardingState',
					actionSubjectId: 'editorOnboarding',
					source,
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const fireEditorPublishSuccessFlagViewed = useCallback(
		(type: any) => {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'onboardingEditorPublishSuccessFlag',
					attributes: {
						type,
					},
				},
			}).fire();
		},
		[createAnalyticsEvent],
	);

	const fireEditorModalPromptClosed = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'onboardingEditorModalPrompt',
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'closeButton',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	const fireEditorModalPromptPublished = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: 'onboardingEditorModalPrompt',
				actionSubject: 'button',
				action: 'clicked',
				actionSubjectId: 'publishButton',
			},
		}).fire();
	}, [createAnalyticsEvent]);

	return {
		fireGetEditorOnboardingStateFailed,
		fireEditorPublishSuccessFlagViewed,
		fireEditorModalPromptClosed,
		fireEditorModalPromptPublished,
	};
};

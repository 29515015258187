import gql from 'graphql-tag';

export const LicenseDetailsFragment = gql`
	fragment LicenseDetailsFragment on Query {
		license {
			licenseConsumingUserCount
			trialEndDate
		}
	}
`;

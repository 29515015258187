import { createStore, createStateHook, createActionsHook } from 'react-sweet-state';

const Store = createStore({
	initialState: {
		/**
		 * The user has published their first page ever in the current session
		 * If they publish more than one page, this condition is invalidated
		 */
		hasMetPublishNudgeConditions: false,
		hasLandInEditorBannerVisible: false,
	},
	actions: {
		setHasMetPublishNudgeConditions:
			(hasMetPublishNudgeConditions: boolean) =>
			({ setState }) => {
				setState({
					hasMetPublishNudgeConditions,
				});
			},
		setHasLandInEditorBannerVisible:
			(hasLandInEditorBannerVisible: boolean) =>
			({ setState }) => {
				setState({
					hasLandInEditorBannerVisible,
				});
			},
	},
	name: 'useOnboardingEditorState',
});

export const useOnboardingEditorState = createStateHook(Store, {
	selector: (state) => ({
		hasMetPublishNudgeConditions: state.hasMetPublishNudgeConditions,
		hasLandInEditorBannerVisible: state.hasLandInEditorBannerVisible,
	}),
});

export const useOnboardingEditorActions = createActionsHook(Store);

import { differenceInDays } from 'date-fns';

export const getTrialDaysLeft = (trialEndDate?: string | null): number => {
	if (!trialEndDate) {
		return 0;
	}

	// +1 day to round up for fractional days
	return differenceInDays(new Date(trialEndDate), new Date()) + 1;
};

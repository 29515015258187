import type { ApolloError } from 'apollo-client';

import { AccessStatus, useSessionData } from '@confluence/session-data';

import { useSitePersonalizationQuery } from '../useSitePersonalization/useSitePersonalizationQuery';

export const CONFLUENCE_ACTIVATION_DATE = 'confluence.ondemand.activationDate';
export const JSW_ACTIVATION_DATE = 'jira-software.ondemand.activationDate';
export const JWM_ACTIVATION_DATE = 'jira-workmanagement.ondemand.activationDate';
// Jira Core was renamed to JWM in 2021 - it exists as a fallback but existing traits should already be migrated
export const JIRA_CORE_ACTIVATION_DATE = 'jira-core.ondemand.activationDate';

const isSiteTraitActivationOrderEligible = (
	attributes: { name: string; value: string }[],
): boolean => {
	const siteTraits = attributes?.reduce<Record<string, string>>(
		(obj, item) => ({ ...obj, [item.name]: item.value }),
		{},
	);
	const jiraOnDemandDate =
		Date.parse(siteTraits?.[JSW_ACTIVATION_DATE]) ||
		Date.parse(siteTraits?.[JWM_ACTIVATION_DATE]) ||
		Date.parse(siteTraits?.[JIRA_CORE_ACTIVATION_DATE]);
	const confluenceOnDemandDate = Date.parse(siteTraits?.[CONFLUENCE_ACTIVATION_DATE]);
	return (
		!!jiraOnDemandDate && !!confluenceOnDemandDate && confluenceOnDemandDate > jiraOnDemandDate
	);
};

/**
 * Determine if the user is a crossflow user.
 * This follows the implementation in useXflowNudgeToInviteExperiment by the Growth team.
 * Note there is another implementation in Onboarding.tsx looking at active licensed products from the tenant context.
 *
 * @param {boolean} skip - if the site personalization query should be skipped
 * @returns {Object} - an object containing:
 * @returns {boolean} isXflowUser - determines if the user is a crossflow user by product activation date
 * @returns {boolean} isLoading - if the site personalization query is still loading
 */
export const useIsXflowUser = ({
	skip = false,
}: {
	skip: boolean;
}): { isXflowUser: boolean; isLoading: boolean; error: ApolloError | undefined } => {
	const { cloudId, isLoggedIn, accessStatus } = useSessionData();
	const hasLicensedAccessStatus = [
		AccessStatus.LICENSED_ADMIN_ACCESS,
		AccessStatus.LICENSED_USE_ACCESS,
	].includes(accessStatus as AccessStatus);

	const skipConditions = skip || !isLoggedIn || !hasLicensedAccessStatus;

	const {
		attributes,
		loading: sitePersonalizationLoading,
		error: sitePersonalizationError,
	} = useSitePersonalizationQuery({
		cloudId,
		skip: skipConditions,
	});

	if (sitePersonalizationLoading || sitePersonalizationError || skipConditions) {
		return {
			isXflowUser: false,
			isLoading: sitePersonalizationLoading,
			error: sitePersonalizationError,
		};
	}

	return {
		isXflowUser: skipConditions
			? false
			: Boolean(attributes) && isSiteTraitActivationOrderEligible(attributes),
		isLoading: sitePersonalizationLoading,
		error: sitePersonalizationError,
	};
};

import gql from 'graphql-tag';

import { LicenseDetailsFragment } from '../LicenseDetails.fragment';

export const PremiumTipTrialDetailsQuery = gql`
	query PremiumTipTrialDetailsQuery {
		...LicenseDetailsFragment
	}
	${LicenseDetailsFragment}
`;

import { useQuery } from '@apollo/react-hooks';
import type { ApolloError } from 'apollo-client';

import { fg } from '@confluence/feature-gating';
import { isErrorMarkedAsHandled, isGraphQLError, markErrorAsHandled } from '@confluence/graphql';
import { expValEquals } from '@confluence/feature-experiments';
import { ConfluenceEdition, useSessionData } from '@confluence/session-data';

import { getTrialDaysLeft } from '../getTrialDaysLeft';
import { useReverseTrialEligible } from '../useReverseTrialEligible';
import { ReverseTrialCohort } from '../__types__/InstanceIsInReverseTrialQuery';

import { PremiumTipTrialDetailsQuery } from './PremiumTipTrialDetailsQuery.graphql';

const handleError = (error: ApolloError | null | undefined) => {
	if (error && (!isErrorMarkedAsHandled(error) || isGraphQLError(error))) {
		markErrorAsHandled(error);
	}
};

export const useEligibilityBiteSizedPremiumMessages = () => {
	const { edition, isLoggedIn } = useSessionData();

	const isReverseTrialQueryEligible = edition === ConfluenceEdition.PREMIUM && isLoggedIn;
	const {
		error: reverseTrialError,
		reverseTrialCohort,
		loading: reverseTrialLoading,
	} = useReverseTrialEligible({ skip: !isReverseTrialQueryEligible });

	handleError(reverseTrialError);

	const isTrialDetailsQueryNotEligible =
		reverseTrialLoading ||
		Boolean(reverseTrialError) ||
		[ReverseTrialCohort.ENROLLED, undefined, null].includes(reverseTrialCohort);

	const {
		data: trialData,
		loading: trialDataLoading,
		error: trialDetailsError,
	} = useQuery(PremiumTipTrialDetailsQuery, { skip: isTrialDetailsQueryNotEligible });

	handleError(trialDetailsError);

	const trialDaysLeft = getTrialDaysLeft(trialData?.license?.trialEndDate);

	const isFeatureEligibleToDisplay =
		trialDaysLeft > 0 &&
		trialDaysLeft < 28 &&
		isReverseTrialQueryEligible &&
		!isTrialDetailsQueryNotEligible &&
		!trialDataLoading &&
		!trialDetailsError &&
		expValEquals('bite_sized_feature_tasting_premium_messages', 'cohort', 'experiment');
	const isPhase2Enabled = isFeatureEligibleToDisplay && fg('cc_editions_bite_sized_phase2');
	return {
		isFeatureFlagEnabled: isFeatureEligibleToDisplay,
		isPhase2Enabled,
		daysLeft: trialDaysLeft,
	};
};

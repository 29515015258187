import React from 'react';
import { css } from '@compiled/react';
import { defineMessages, useIntl } from 'react-intl-next';

import { N0 } from '@atlaskit/theme/colors';
import { Flex, xcss, Box } from '@atlaskit/primitives';
import EditorAlignLeftIcon from '@atlaskit/icon/core/migration/align-left--editor-align-left';
import CommentIcon from '@atlaskit/icon/core/migration/comment';
import { token } from '@atlaskit/tokens';
import { IconButton } from '@atlaskit/button/new';

import { AtlassianIntelligenceLogoDark } from '@atlassian/ai-components';

import { useUnreadInlineComments } from '@confluence/unread-comments';
import { PageHeaderLoomEntryPoint } from '@confluence/page-header-loom-button';
import { useObjectSidebar } from './useObjectSidebar';

const objectSidebarStyles = css({
	position: 'fixed',
	right: 0,
	bottom: 0,
	paddingRight: `${token('space.300', '24px')}`,
	paddingBottom: `${token('space.100', '8px')}`,
	zIndex: 1,
});

const objectSidebarContainerStyles = xcss({
	border: `1px solid ${token('color.border', '#0B122824')}`,
	padding: 'space.100',
	boxShadow: 'elevation.shadow.raised',
	backgroundColor: 'elevation.surface.raised',
	borderRadius: '64px',
});

const dividerStyle = xcss({
	borderBottom: `2px solid ${token('color.border', '#0B122824')}`,
	display: 'inline-block',
	width: '16px',
	height: '1px',
});

const bottomSidebarStyles = xcss({
	marginTop: 'space.100',
});

const dotIconStyle = css({
	height: '6px',
	width: '6px',
	backgroundColor: token('color.icon.brand', '#0052CC'),
	borderRadius: '50%',
	border: `2px solid ${token('color.border.inverse', N0)}`,
	position: 'absolute',
	right: '3px',
	top: '3px',
});

const commentIconContainerStyle = css({
	position: 'relative',
});

const i18n = defineMessages({
	openCommentsPanelLabel: {
		id: 'object-sidebar.open.comments.panel.label',
		defaultMessage: 'Open Comments Panel',
		description: 'Label for button to open the comments panel',
	},
	openDetailsPanelLabel: {
		id: 'object-sidebar.open.details.panel.label',
		defaultMessage: 'Open Details Panel',
		description: 'Label for button to open the details panel',
	},
	recordLoomLabel: {
		id: 'object-sidebar.record.loom.label',
		defaultMessage: 'Record a loom',
		description: 'Label for button to record a loom',
	},
	aiLogoAltText: {
		id: 'object-sidebar.floating-context-menu-button.logo-alt-text',
		defaultMessage: 'Atlassian Intelligence',
		description: 'The alt text for the Atlassian Intelligence logo icon',
	},
	unreadCommentsLabel: {
		id: 'object-sidebar.unread.comments.icon.label',
		description: 'aria-label for the comment button to display unread inline comments',
		defaultMessage: 'You have unread comments',
	},
});

type ObjectSidebarControlProps = {
	hasCommentsButton?: boolean;
	hasDetailsButton?: boolean;
	hasLoomButton?: boolean;
	hasAiButton?: boolean;
	pageMode: 'view' | 'edit';
	contentId: string;
	spaceKey?: string;
	contentType?: string | null;
};

export const ObjectSidebarControl = ({
	hasCommentsButton,
	hasDetailsButton,
	hasLoomButton,
	hasAiButton,
	contentId,
	spaceKey,
	contentType,
}: ObjectSidebarControlProps) => {
	const { formatMessage } = useIntl();
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const [{ sidebarControlState }, { showObjectSidebar }] = useObjectSidebar();

	const handleClickCommentsButton = () => {
		//TODO update to actual panel id
		showObjectSidebar({ id: 'comments-panel' });
	};
	const handleClickDetailsButton = () => {
		//TODO update to actual panel id
		showObjectSidebar({ id: 'details-panel' });
	};
	const handleClickAiButton = () => {
		//TODO: Implement
	};

	const primaryActions: JSX.Element[] = [];
	if (hasCommentsButton) {
		primaryActions.push(
			<div css={commentIconContainerStyle}>
				<IconButton
					onClick={handleClickCommentsButton}
					appearance="subtle"
					shape="circle"
					icon={CommentIcon}
					label={formatMessage(i18n.openCommentsPanelLabel)}
					testId="sidebar-comments-button"
				/>
				{unreadCommentsListState.length > 0 && (
					<div css={dotIconStyle} aria-label={formatMessage(i18n.unreadCommentsLabel)} />
				)}
			</div>,
		);
	}
	if (hasDetailsButton) {
		primaryActions.push(
			<IconButton
				onClick={handleClickDetailsButton}
				appearance="subtle"
				shape="circle"
				icon={EditorAlignLeftIcon}
				label={formatMessage(i18n.openDetailsPanelLabel)}
				testId="sidebar-details-button"
			/>,
		);
	}

	const secondaryActions: JSX.Element[] = [];
	if (hasLoomButton) {
		secondaryActions.push(
			<PageHeaderLoomEntryPoint
				contentId={contentId}
				spaceKey={spaceKey ?? ''}
				contentType={contentType ?? ''}
				isCircle
				testId="sidebar-loom-button"
			/>,
		);
	}

	return sidebarControlState === 'OPEN' ? (
		<div css={objectSidebarStyles} data-testid="object-sidebar-control">
			{primaryActions.length + secondaryActions.length > 0 && (
				<Flex
					xcss={objectSidebarContainerStyles}
					direction="column"
					gap="space.100"
					justifyContent="center"
					alignItems="center"
				>
					{primaryActions}
					{secondaryActions.length > 0 && <Box xcss={dividerStyle} />}
					{secondaryActions}
				</Flex>
			)}
			{hasAiButton && (
				<Flex xcss={[objectSidebarContainerStyles, bottomSidebarStyles]}>
					<IconButton
						onClick={handleClickAiButton}
						appearance="subtle"
						shape="circle"
						icon={AtlassianIntelligenceLogoDark}
						label={formatMessage(i18n.aiLogoAltText)}
						testId="sidebar-ai-button"
					/>
				</Flex>
			)}
		</div>
	) : null;
};

import { useEffect, useState } from 'react';

import { getMonitoringClient } from '@confluence/monitoring';
import { Attribution } from '@confluence/error-boundary';
import { getAnalyticsWebClient } from '@confluence/analytics-web-client';
import { AccessStatus, useSessionData } from '@confluence/session-data';

import { getPubSubClient } from './client';
import { eventToAvi, getCurrentProtocolType, type PubSubClient } from './PubSubClient';

type UsePubSubEventProps = {
	contentId: string;
	contentType: string;
	eventName: string;
	onEvent: (...args: any[]) => void;
	attribution?: Attribution;
	skip?: boolean;
};

export const errorStringsToIgnore = ['401', 'Failed to fetch', 'Load Failed', 'Network error'];

const isIgnoredPubSubError = (error: any) => {
	return errorStringsToIgnore.some((errorString) => error?.message.includes(errorString));
};

// Note: export for testing purposes only
export const getEventHandler = (eventName: string, onEvent: any) => {
	return (...args: any[]) => {
		onEvent(...args);
		getAnalyticsWebClient().then(
			(client) => {
				client.sendOperationalEvent({
					action: 'received',
					actionSubject: 'pubsub.event',
					actionSubjectId: eventName,
					source: 'usePubSubEvent',
				});
			},
			() => {},
		);
	};
};

export const usePubSubEvent = ({
	contentId,
	contentType,
	eventName,
	onEvent,
	attribution,
	skip: skipProp = false,
}: UsePubSubEventProps) => {
	const [pubSubClient, setPubSubClient] = useState<PubSubClient | undefined>(undefined);
	const { accessStatus } = useSessionData();
	// if user is anonymous, skip
	const skip = accessStatus === AccessStatus.ANONYMOUS_ACCESS || skipProp;

	useEffect(() => {
		if (skip) {
			return;
		}

		const pubSubPromise = new Promise<PubSubClient>((resolve) => {
			void getPubSubClient().then((psc) => {
				void psc
					.joinChannel(contentType, contentId)
					.then(() => {
						setPubSubClient(psc);

						// Temporarily fire events when PubNub protocol is detected
						// to investigate source of requests
						if (getCurrentProtocolType(psc.client) === 'pubnub') {
							const { analyticsClient, ...config } = (psc.client as any)?.config;
							// copy config obj so we can scrub URL from logs
							const configCopy = JSON.parse(JSON.stringify(config));
							delete configCopy.apsProtocol?.url;
							getAnalyticsWebClient().then((client) => {
								client.sendOperationalEvent({
									action: 'using.pubnub',
									actionSubject: 'client',
									source: 'usePubSubEvent',
									attributes: {
										contentId,
										contentType,
										config: configCopy,
									},
								});
							});
						}

						resolve(psc);
					})
					.catch((error) => {
						if (!isIgnoredPubSubError(error)) {
							const e = new Error(
								`PubSub (${getCurrentProtocolType(
									psc.client,
								)}) - failed to join channel - ${error}`,
							);
							getMonitoringClient().submitError(e, {
								attribution: attribution || Attribution.PAGE_EXTENSIONS,
							});
						}
					});
			});
		});

		return () => {
			void pubSubPromise.then((psc) => {
				setPubSubClient(undefined);
				void psc.leaveChannel(contentType, contentId);
			});
		};
	}, [contentType, contentId, attribution, skip]);

	useEffect(() => {
		if (!pubSubClient) {
			return;
		}

		const avi = eventToAvi(eventName);
		const eventHandler = getEventHandler(avi, onEvent);
		try {
			pubSubClient.subscribeSync(avi, eventHandler);
		} catch (error) {
			const e = new Error(
				`PubSub (${getCurrentProtocolType(
					pubSubClient.client,
				)}) - failed to subscribe to ${eventName} - ${error}`,
			);
			getMonitoringClient().submitError(e, {
				attribution: attribution || Attribution.PAGE_EXTENSIONS,
			});
		}

		return () => {
			pubSubClient.unsubscribeSync(avi, eventHandler);
		};
	}, [pubSubClient, eventName, onEvent, attribution]);
};

import { createStore, createHook } from 'react-sweet-state';
import type { StoreActionApi } from 'react-sweet-state';

export type ObjectSidebarBehavior = 'push' | 'cover';
export type ObjectSidebarPanelId = string; // Possible enum?
export type ObjectSidebarCloseOptions = {
	canClosePanel: () => boolean; // Returns true if panel can be closed
	onPanelClose: () => void;
};
export type ObjectSidebarPanel = {
	id: ObjectSidebarPanelId;
	closeOptions?: ObjectSidebarCloseOptions;
	params?: any;
};

export type ObjectSidebarState = {
	isObjectSidebarShown: boolean;
	behavior: ObjectSidebarBehavior;
	panel?: ObjectSidebarPanel;
	sidebarControlState: 'OPEN' | 'HIDDEN';
};

export const initialState: ObjectSidebarState = {
	isObjectSidebarShown: false,
	behavior: 'push',
	panel: undefined,
	sidebarControlState: 'OPEN',
};

export const actions = {
	showObjectSidebar:
		(panel: ObjectSidebarPanel, behavior?: ObjectSidebarBehavior) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel: currentPanel, behavior: currentBehavior } = getState();

			// Check if panel is open
			if (isObjectSidebarShown) {
				// If it's the same panel do nothing
				if (currentPanel?.id === panel.id) {
					return;
				}

				// Otherwise change it
				setState({ panel, behavior: behavior ?? currentBehavior });
			}

			// Set the panel to be open
			setState({
				isObjectSidebarShown: true,
				panel,
				behavior: behavior ?? currentBehavior,
				sidebarControlState: 'HIDDEN',
			});

			// Close the global panel
			window.dispatchEvent(new Event('closeGlobalSidebar'));
		},
	hideObjectSidebar:
		() =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel } = getState();

			// If we're not showing a panel, don't do anything
			if (!isObjectSidebarShown || !panel) {
				return;
			}

			// If the user has provided closeOptions check them
			if (panel.closeOptions) {
				// If the panel closing check returns false, do nothing
				if (!panel.closeOptions.canClosePanel()) {
					return;
				}

				// Call the onClose callback
				panel.closeOptions.onPanelClose();
			}

			// Close the panel
			setState({ isObjectSidebarShown: false, panel: undefined, sidebarControlState: 'OPEN' });
		},
	changePanelBehavior:
		(newBehavior: ObjectSidebarBehavior) =>
		({ setState }: StoreActionApi<ObjectSidebarState>) => {
			setState({ behavior: newBehavior });
		},
	changePanel:
		(newPanel: ObjectSidebarPanel, newBehavior?: ObjectSidebarBehavior) =>
		({ setState, getState }: StoreActionApi<ObjectSidebarState>) => {
			const { isObjectSidebarShown, panel, behavior } = getState();

			// Only change panels if the panel is open
			if (isObjectSidebarShown && panel) {
				// If it's the same panel do nothing
				if (panel.id === newPanel.id) {
					return;
				}

				// If the user has provided closeOptions check them
				if (panel.closeOptions) {
					// If the panel closing check returns false, do nothing
					if (!panel.closeOptions.canClosePanel()) {
						return;
					}

					// Call the onClose callback
					panel.closeOptions.onPanelClose();
				}

				setState({ panel: newPanel, behavior: newBehavior ?? behavior });
			}

			// Otherwise do nothing
		},
};

export type ObjectSidebarActions = typeof actions;

export const ObjectSidebarStore = createStore({
	initialState,
	actions,
	name: 'ObjectSidebarStore',
});

export const useObjectSidebar = createHook(ObjectSidebarStore);

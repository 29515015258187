/* eslint-disable @atlaskit/design-system/ensure-design-token-usage */
/* eslint-disable @atlaskit/design-system/ensure-design-token-usage/preview */
import React from 'react';

import AtlassianIntelligenceIcon from '@atlaskit/icon/core/atlassian-intelligence';
import type { GlyphProps } from '@atlaskit/icon/types';
import { AtlassianIntelligenceDarkIcon } from '@atlaskit/legacy-custom-icons';
import { token } from '@atlaskit/tokens';

export const AtlassianIntelligenceLogoDark = (props: GlyphProps) => (
	<AtlassianIntelligenceIcon
		color={token('color.icon')}
		LEGACY_fallbackIcon={AtlassianIntelligenceDarkIcon}
		{...props}
	/>
);

import gql from 'graphql-tag';

export const LabelsQuery = gql`
	query OnboardingLabelsQuery($contentId: ID!, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				id
				labels {
					nodes {
						id
						label
					}
				}
				metadata {
					sourceTemplateEntityId
				}
			}
		}
	}
`;
